import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { Box, Typography, CircularProgress, Grid, Avatar } from "@mui/material";
import { colors } from "../lib/theme";
import { db } from "../../shared/firebase";
import RefreshLink from "../../shared/RefreshLink";
import { useAuth } from "../../shared/useAuth";
import AlbumArt from "../../shared/AlbumArt";
import ContainerBox from "../../shared/ContainerBox";

const reactRootDataset = document.getElementById("react-root").dataset;

const User = () => {
  const { user: currentUser, loading: userLoading } = useAuth();

  const userId = reactRootDataset.userId;

  const [user, setUser] = useState(null);
  const [albums, setAlbums] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sharedAlbums, setSharedAlbums] = useState([]);

  useEffect(() => {
    const fetchUserAndAlbums = async () => {
      try {
        // Fetch user data
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          setError("User not found");
        }

        // Fetch albums with privacyLevel 2
        const albumsRef = collection(db, "albums");
        const q = query(
          albumsRef,
          where("userId", "==", userId),
          where("privacyLevel", "==", 2)
        );
        const albumsSnapshot = await getDocs(q);
        const albumsList = albumsSnapshot.docs.map((doc) => {
          const albumData = { id: doc.id, ...doc.data() };
          console.log('Album data:', albumData);
          return albumData;
        });
        setAlbums(albumsList);

        // Fetch albums shared with the current user
        if (currentUser) {
          const sharedAlbumsRef = collection(db, "albums");
          const sharedQuery = query(
            sharedAlbumsRef,
            where("sharedWith", "array-contains", currentUser.uid),
            where("userId", "==", userId)
          );
          const sharedAlbumsSnapshot = await getDocs(sharedQuery);
          const sharedAlbumsList = sharedAlbumsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSharedAlbums(sharedAlbumsList);
        }

        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("An error occurred while fetching data");
        setLoading(false);
      }
    };

    fetchUserAndAlbums();
  }, [userId, currentUser]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ color: colors.navy }} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const renderAlbumGrid = (albumList, emptyMessage, isSharedAlbums = false) => (
    <Grid container spacing={3}>
      {albumList.map((album) => (
        <Grid item xs={12} sm={6} md={4} key={album.id}>
          <Box sx={{
            mb: 2,
            display: "flex",
            flexDirection: isSharedAlbums ? "row" : "column",
            alignItems: isSharedAlbums ? "center" : "flex-start"
          }}>
            <RefreshLink to={`/album/${album.id}`}>
              <AlbumArt imageUrl={album.imageUrl} title={album.title} size={100} />
            </RefreshLink>
            <Box sx={{
              ml: isSharedAlbums ? 2 : 0,
              mt: isSharedAlbums ? 0 : 2,
              textAlign: 'left',
              flexGrow: isSharedAlbums ? 1 : 0,
              width: isSharedAlbums ? 'calc(100% - 116px)' : '100%' // 100px for AlbumArt + 16px for margin
            }}>
              <Typography variant="h6" noWrap>
                <RefreshLink to={`/album/${album.id}`}>
                  {album.title}
                </RefreshLink>
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
      {albumList.length === 0 && (
        <Grid item xs={12}>
          <Typography>{emptyMessage}</Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <>
      <ContainerBox>
        <Helmet>
          <title>
            {user ? `${user.firstName} ${user.lastName} - ` : ""} - Golden Record
          </title>
        </Helmet>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          {user && user.profilePhotoURL && (
            <Avatar
              src={user.profilePhotoURL}
              alt={`${user.firstName} ${user.lastName}`}
              sx={{ width: 60, height: 60, mr: 2 }}
            />
          )}
          <Typography variant="h3">
            {user ? `${user.firstName} ${user.lastName}` : "User"}
          </Typography>
        </Box>
        <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
          Public Albums
        </Typography>
        {renderAlbumGrid(albums, "This user has no public albums.")}

        {currentUser && currentUser.uid !== userId && (
          <>
            <Typography variant="h5" gutterBottom sx={{ mt: 4, mb: 2 }}>
              Shared with you
            </Typography>
            {renderAlbumGrid(sharedAlbums, "This user hasn't shared any albums with you.", true)}
          </>
        )}
      </ContainerBox>
    </>
  );
};

export default User;
